.suivistextebloc-container{
    height: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: var(--color-marron);

    h3,p,ul {
        color: var(--color-grisrose);
        z-index: 1;
        margin-bottom: 4%;
    }
    h3{
        text-decoration: underline;
    }

    p, ul{
        font-family: var(--font-2),'Courier New', Courier, monospace;
        font-size: 24px;
        list-style: none;
        padding: 0 5%;
    }
}

@media screen and (max-width: 767px){
    .suivistextebloc-container{
        height: 1400px;

        h3{
            font-size: 30px;
            margin: 0 2% 8% 2%;

        }
        p, li {
            font-size: 20px;
        }
    }
}