.presentation-accueil-container{
    background-color: var(--color-grisrose);
    height: auto;
    margin: 0;
    .svg-fond-rose{
    position: relative;    
    background-color: var(--color-saumon);
    .svgaccueil{
        
        img{
        width: 100%;
        height: auto;
        min-height: 800px;
        position: absolute;
        z-index: 1;
    }
}

    .accueilphotoblock{
        position: relative;
        z-index: 2;
        height: 35%;
    }

    }    
.acceuiltexteblock{
        margin-top: 38%;
        padding-bottom: 5%;
    }
}

@media screen and (max-width: 767px){
    .presentation-accueil-container{
        .svg-fond-rose{
            height: 750px;
            .svgaccueil{
                img{
                    height: 1400px;
                }
            }
            .accueilphotoblock{
                height: 100%;
            }
        }
    }
}