.error-page-container{
    text-align: center;
    h3{
        margin-top: 12%;
        margin-bottom: 3%;
    }
    .errorlink{
        text-decoration: none;
        color: var(--color-marron);
        font-size: 24px;
        font-family: var(--font-3),'Courier New', Courier, monospace;

    }
    .footer{
        margin-top: 6%;
    }
}