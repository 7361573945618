.carrousel-container{
    display: flex;
}

.arrow-right , .arrow-left{  
    display: block;
    margin: 30px auto;
    width: 25px;
    height: 25px;
    border-top: 2px solid var(--color-marron);
    border-left: 2px solid var(--color-marron);
    position: absolute;
}

.arrow-right{
    transform: rotate(135deg);
    right: 5%;
    cursor: pointer;
}
.arrow-left{
    transform: rotate(-45deg);
    left: 5%;
}

@media screen and (max-width: 767px){
    .arrow-right{
        right: 3%;
    }
    .arrow-left{
        left: 3%;
    }
}