.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    p {
        color: var(--color-marron);
        font-family: var(--font-2), 'Courier New', Courier, monospace;
        width: 100%;
    }

    .third-bloc-footer {
        flex: 1;
    }
    .footer-link-block {
        display: flex;
        justify-content: center;
        gap: 5%;
    }
    a {
        display: flex;
        text-decoration: none;
        align-items: center;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .footer {
        .footer-link-block {
            gap: 2%;
            margin-bottom: 2%;
        }
        p {
            font-size: 14px;
        }
        .third-bloc-footer {
            margin-bottom: 2%;
        }
    }
}

@media screen and (max-width: 767px) {
    .footer {
        .footer-link-block {
            gap: 2%;
        }
        p {
            font-size: 13px;
            margin-bottom: 2%;
        }
        a {
            flex-direction: column;
        }
        .third-bloc-footer {
            margin-bottom: 2%;
            height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;
        }
        .logofooter {
            img {
                width: 150px;
            }
        }
    }
}
