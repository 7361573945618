* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* Charm-Bold */
@font-face {
  font-family: 'Charm-Bold';
  src: url('../assets/fonts/Charm-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Charm-Regular */
@font-face {
  font-family: 'Charm-Regular';
  src: url('../assets/fonts/Charm-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Jura-Regular */
@font-face {
  font-family: 'Jura-Regular';
  src: local('Jura-Regular'), url('../assets/fonts/Jura-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Revive 80 Signature */
@font-face {
  font-family: 'Revive80Signature';
  src: url('../assets/fonts/Revive\ 80\ Signature.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
    background-color: #E7B68E;
    max-width: 1440px;
    margin: auto;    
    --font-1 : 'Revive 80 Signature';
    --font-2 : 'Charm';
    --font-3 : 'Jura-Regular';
    --color-marron : #7D4218;
    --color-saumon : #E7B68E;
    --color-grisrose : #FBF0E8;




    h1 {
        font-family: var(--font-3),'Courier New', Courier, monospace;
        font-size: 80px;
        color: var(--color-grisrose);
        text-shadow:  4px 4px 4px rgba(0, 0, 0, 1);
    }

    h2 {
        font-family: var(--font-3),'Courier New', Courier, monospace;
        font-size: 50px;
        color: var(--color-marron);
    }

    h3 {
        font-family: var(--font-3),'Courier New', Courier, monospace;
        font-size: 35px;
        color: var(--color-grisrose);
    }
    h4 {
        font-family: var(--font-2),'Courier New', Courier, monospace;
        font-size: 30px;
        color: var(--color-grisrose);
    }

}




