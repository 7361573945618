.accueil-element {
    max-width: 100%;
    position: relative;
    h1 {
        display: flex;
        position: absolute;
        width: 100%;
        top: 15vh;
        z-index: 1;
        background-color: rgba(231, 182, 142, 0.6);
        line-height: 150px;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .imgaccueil {
        height: 600px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 1;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .accueil-element {
        h1 {
            font-size: 64px;
            text-align: center;
            line-height: 100px;
        }
    }
}

@media screen and (max-width: 767px) {
    .accueil-element {
        h1 {
            font-size: 40px;
            text-align: center;
            line-height: 80px;
            top: 7vh;
        }
        .imgaccueil {
            height: 300px;
        }
    }
}
