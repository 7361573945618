.mentions-container {
    padding: 5% 5% 5% 10%;
    background-color: var(--color-grisrose);

    h3 {
        margin: 3% 0;
        text-decoration: underline;
        color: var(--color-marron);
    }
    a,
    p {
        font-size: 18px;
        color: var(--color-marron);
        font-family: var(--font-2), 'Courier New', Courier, monospace;
    }
    p {
        margin: 1% 0;
    }
    a {
        margin: 0 5px;
    }
}
