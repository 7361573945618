.accueiltexteblock-container{
    display: flex;
    flex-direction: column;
    gap: 5vh;

    .logo-text-block{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        img {
            width: 64px;
            margin-left: 10vw;
            align-self: center;
        }
        p{
            font-family: var(--font-2),'Courier New', Courier, monospace;
            font-size: 20px;
            color: var(--color-marron);
            width: 70%;
            text-align: center;
            padding-right: 3vw;
        }
    }
}

@media screen and (max-width: 767px){
    .accueiltexteblock-container{
        .logo-text-block{
            img{
                width: 48px;
            }
            p{
                font-size: 18px;
            }
        }
    }
}