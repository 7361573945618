.tarifs-block-container{
    background-color: var(--color-saumon);
    height: auto;
    text-align: center;
    padding: 3%;


    h2{
        font-size: 60px;
        margin-bottom: 5%;
        border: 1px solid var(--color-marron);
    }
    p{
        font-family: var(--font-2),'Courier New', Courier, monospace;
        color: var(--color-marron);
        font-size: 24px;
    }
    h3{
        margin-bottom: 2%;
        font-size: 50px;
        text-decoration: underline;
    }
    h4{
        margin: 2% 0;

    }
    .tarifs-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 50px;
        .tarif-col{                
            min-height: 320px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border: 1px solid var(--color-marron);
            box-shadow: 0 4px 4px 0 rgba(59, 31, 11, 0.5);
            border-radius: 15px;
            padding: 1%;
        }    
        .tarif-plan-unique{
            width: 60%;

        }

        .tarifs-colonnes{
            display: flex;
            justify-content: center;
            gap: 5%;
            .tarif-col{
                width: 40%;
                
            }
        }
}
}

@media screen and (max-width: 767px){
    .tarifs-block-container{
        h3{
            font-size: 40px;
        }
        .tarifs-container{
            .tarif-plan-unique{
                width: 100%;
            }
            .tarifs-colonnes{
                flex-direction: column;
                .tarif-col{
                width: 100%;
                margin-bottom: 3%;
            }
            }
        }
    }
}