.navlist {
    display: flex;
    justify-content: center;
    list-style: none;
    gap: 2%;
    margin-top: 4%;
    margin-bottom: 2%;
    li{
        font-family: var(--font-2), 'Courier New', Courier, monospace;
        font-size: 42px;
        color: var(--color-marron);

    }

    a{
    text-decoration: none;
       padding: 0 15px 0 15px ;        
       display: flex;
        align-items: center;
    }
    
    
    .nav-active {
        background-color: var(--color-marron);
        border-radius: 5px;
        li{
            color: var(--color-grisrose);
            
        }
    }
}



@media screen and (min-width: 768px) and (max-width: 1024px){
    .navlist{
        gap: 8px;
        flex-wrap: wrap;
        li{
            font-size: 30px;
        }
    }
}

@media screen and (max-width: 767px){
    .navlist{
        gap: 10px;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 3%;
        li{
            font-size: 28px;
            
        }
        a{
          padding: 0 5px 0 5px;  
        }
    }
}