.avisclient-container {
    padding-bottom: 3%;
    h3{
        text-align: center;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    p{
        text-align: center;
        font-family: var(--font-2),'Courier New', Courier, monospace;
        font-size: 20px;
        color: var(--color-marron);
        margin-top: 2%;
    }
    img{
        border-radius: 5px;
        box-shadow: 0 4px 4px 0 rgba(59, 31, 11 , 0.5);
    }
    .clientscontainer{
        display: flex;
        justify-content: space-between;
        margin-bottom: 3%;
    
        .avis-ilana-container{
            display: flex;
            margin-left: 5%;
            gap: 15%;
            width: 50%;
            justify-content: center;
            align-items: center;
        }

        .avis-annemarie-container{
            display: flex;
            flex-direction: column;
            width: 50%;
            justify-content: center;
            align-items: center;
        }
        .img-av-ap{
            width: 150px;
        }
        .img-msg{
            width: 280px;

        }
}
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .avisclient-container{
        .clientscontainer{
            flex-direction: column;
            align-items: center;
            gap: 15%;
            .avis-ilana-container{
                margin-bottom: 3%;
                margin-left: 0;
            }
            .img-av-ap{
                width: 120px;
            }
            .img-msg{
                width: 250px;
            }
        }
    }
}

@media screen and (max-width: 767px){
        .avisclient-container{
            padding: 0 3%;
            h3{
                font-size: 28px;
            }
        .clientscontainer{
            flex-direction: column;
            align-items: center;
            gap: 15%;
            .avis-ilana-container{
                flex-direction: column;
                margin-bottom: 3%;
                margin-left: 0;
            }
            .ilana-av-ap{
                display: flex;
                flex-direction: row;
                gap: 10%;
                justify-content: center;
                margin-bottom: 10%;
            }
            .img-av-ap{
                width: 110px;
                height: 120px;
                object-fit: cover;
            }
            .img-msg{
                width: 280px;
            }
        }
    }
}