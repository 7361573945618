.page-suivis-container {
    background-color: var(--color-grisrose);
    .svgfondmarron{
        position: relative;
    
    .svg-vague-marron{
        width: 100%;
        height: auto;
        min-height: 1200px;
        position: absolute;
        bottom: 40%;
        z-index: 1;
        transform: rotateX(180deg);
    }
}
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .page-suivis-container{
        .svgfondmarron{
        .svg-vague-marron{
            bottom: 45%;
        }}
    }
}

@media screen and (max-width: 767px){
    .page-suivis-container{
        .svgfondmarron{
            margin-top: 10%;
        .svg-vague-marron{
            min-height:1400px;
            bottom: 48%;
        }}
    }
}