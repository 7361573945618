.carousel-block{
    .btn-arrow{
        border: none;
    }
    display: flex;
    height: 800px;
    width: 100%;
    align-items: center;
    position: relative;
    .slideInner___2mfX9{
        position: relative;
    }

    .carousel-img-text{         
        background-color: var(--color-grisrose);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10%;
        height: 800px;

        img{
            width: 250px;
            box-shadow: 0 4px 4px 0 rgba(59, 31, 11, 0.5);
        }
        p{
            width: 40%;
            font-family: var(--font-2),'Courier New', Courier, monospace;
            font-size: 24px;
            color: var(--color-marron);
            text-align: justify;
        }
    }
}

@media screen and (max-width: 767px){
    .carousel-block{
        .carousel-img-text{
        flex-direction: column;
        gap: 5%;
        img{
            width: 230px;
        }
        p{
            font-size: 20px;
            width: 80%;
        }
    }
    }
}