.header-logos {
    display: flex;
    justify-content: center;
    padding-top: 3vh;
    width: 100%;
    position: relative;
    
    a{
    display: flex;

}
}

.logo-header {
    width: 350px;
}

.header-little-logos {
    position: absolute;
    right: 6%;
    display: flex;
    gap: 8px;
}

