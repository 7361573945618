.contact-socials-container {
    display: flex;
    height: 250px;
    background-color: var(--color-grisrose);
    .socials-link-block {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        h4,
        p {
            color: var(--color-marron);
        }
        p {
            font-family: var(--font-2), 'Courier New', Courier, monospace;
            font-size: 20px;
        }

        .social-link {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--color-saumon);
            margin-top: 5%;
            text-decoration: none;

            p {
                color: #d98c4d;
                margin: 0 3%;
            }
            .custom-color {
                color: #d98c4d;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .contact-socials-container {
        .socials-link-block {
            h4 {
                font-size: 26px;
            }
            .social-link {
                p {
                    font-size: 16px;
                    margin: 0 1%;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .contact-socials-container {
        flex-direction: column;
        height: auto;
        .socials-link-block {
            margin: 5% 0;
        }
    }
}
