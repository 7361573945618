.reservations-container {
    background-color: var(--color-grisrose);
    height: 1000px;
    padding-top: 3%;

    h3 {
        color: var(--color-marron);
        text-align: center;
    }
}
.calendly-inline-widget {
    height: 800px !important;
}

.prestations-page-container {
    .reservations-container {
        background-color: var(--color-marron);

        h3 {
            color: var(--color-grisrose);
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .reservations-container {
        height: 1200px;
    }
    .calendly-inline-widget {
        height: 1200px !important;
    }
}

@media screen and (max-width: 767px) {
    .reservations-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 1200px;
        h3 {
            font-size: 28px;
            margin-bottom: 3%;
        }
    }
    .calendly-widget {
        width: 90%;
    }
    .calendly-inline-widget {
        height: 1200px !important;
    }
}
