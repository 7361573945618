.prestations-texte-container{
    background-color: var(--color-grisrose);
    height: 400px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 3%;
    align-items: center;
    
    h3, p {
        
        margin-bottom: 3%;
        text-align: center;
        color: var(--color-marron);
        width: 75%;
    }
    h3 {
        font-size: 40px;
        text-decoration: underline;
    }
    p{
        font-family: var(--font-2),'Courier New', Courier, monospace;
        font-size: 28px;
    }
}

@media screen and (max-width: 767px){
    .prestations-texte-container{
        height: 350px;
        h3{
            font-size: 30px;
        }
        p{
            font-size: 20px;
        }
    }
}