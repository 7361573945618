.suivisphotobloc-container {
    height: 1000px;
    display: flex;

    .photoblock-img {
        width: 40%;
        display: flex;
        justify-content: center;
        height: fit-content;
        img {
            width: 60%;
            height: auto;
            border-radius: 40px;
            box-shadow: 0 4px 4px 0 rgba(59, 31, 11, 0.5);
            margin-top: 15vh;
        }
    }
    .photoblock-text {
        width: 60%;
        text-align: center;
        margin-top: 10%;
        p {
            font-family: var(--font-3), 'Courier New', Courier, monospace;
            font-size: 22px;
            color: var(--color-marron);
            width: 80%;
            margin-bottom: 4%;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .suivisphotobloc-container {
        height: 1100px;
        .photoblock-img {
            img {
                margin-top: 25vh;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .suivisphotobloc-container {
        flex-direction: column;
        align-items: center;
        height: 1600px;
        .photoblock-img {
            width: 100%;
            img {
                margin-top: 6%;
            }
        }
        .photoblock-text {
            width: 90%;
            p {
                width: auto;
            }
        }
    }
}
