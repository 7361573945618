.ebook-img{
    width: 250px;
    height: 340px;
    background-size: cover;
    box-shadow: 0 4px 4px 0 rgba(59, 31, 11, 0.5);
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .ebook-img{
        width: 160px;
        height: 250px;
    }
}

@media screen and (max-width: 767px){
        .ebook-img{
        width: 160px;
        height: 250px;
    }
}