.logosaccueil-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 250px;
    width: 100%;
    background-color: var(--color-grisrose);
    margin: 0;

    .logotext-container{
        flex: 1;
        display: flex;
        flex-direction: column; 
        align-items: center;
        p{
            color: var(--color-marron);
            font-family: var(--font-2),'Courier New', Courier, monospace;
            font-size: 24px;
        }
        img{
            width: 64px;
        }
    }
}

@media screen and (max-width: 767px){
    .logosaccueil-container{
        .logotext-container{
            height: 100%;
            justify-content: center;
            text-align: center;
            gap: 5%;
            p{
                font-size: 20px;
            }
            img{
                width: 48px;
            }
        }
    }
}