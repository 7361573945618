.ebook-container {
    background-color: var(--color-grisrose);
    padding-top: 3%;

    h3 {
        color: var(--color-marron);
        text-align: center;
        margin-bottom: 3%;
        margin-left: 6%;
    }
    .ebook-img-btn-container {
        display: flex;
        height: 1000px;
        justify-content: space-between;
        padding: 0 5%;

        .btn-block {
            flex: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            gap: 12%;
            font-family: var(--font-2), 'Courier New', Courier, monospace;
            color: var(--color-marron);
            font-size: 30px;
            img {
                height: 100px;
                position: absolute;
                left: 50%;
            }
            p {
                margin-right: 10%;
            }
            a {
                border: 1px solid var(--color-marron);
                border-radius: 30px;
                box-shadow: 0 4px 4px 0 rgba(59, 31, 11, 0.5);
                line-height: 50px;
                width: 200px;
                text-decoration: none;
                color: var(--color-marron);
            }
        }

        .double-img-block {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .ebookimg1-3 {
                position: absolute;
                width: 250px;
                height: 340px;
                left: 10%;
                top: 25%;
            }
            .ebookimg2-4 {
                position: absolute;
                width: 250px;
                height: 340px;
                top: 40%;
                left: 40%;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .ebook-container {
        .ebook-img-btn-container {
            height: 700px;

            .btn-block {
                img {
                    height: 80px;
                }
            }
            .double-img-block {
                .ebookimg1-3 {
                    left: 5%;
                }
                .ebookimg2-4 {
                    top: 35%;
                    left: 25%;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .ebook-container {
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 28px;
        }
        .ebook-img-btn-container {
            flex-direction: column;
            height: 850px;
            .btn-block {
                flex: 0.5;
                img {
                    height: 60px;
                    transform: rotate(-20deg);
                    margin-left: 20px;
                    margin-bottom: 15px;
                }
            }
            .double-img-block {
                .ebookimg1-3 {
                    top: 10%;
                    left: 20%;
                }
                .ebookimg2-4 {
                    top: 20%;
                    left: 42%;
                }
            }
        }
    }
}
