.photoblock-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h2 {
        text-align: center;
        margin-top: 5vh;
    }

    .photoblock-imgtext-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 12vh;
        gap: 8vw;
        max-height: 460px;
        overflow: hidden;

        .photoblock-img {
            img {
                width: 300px;
                border-radius: 180px;
            }
        }
        .photoblock-text {
            width: 45vw;
            max-width: 650px;
            background-color: var(--color-grisrose);
            text-align: center;
            p {
                font-family: var(--font-2), 'Courier New', Courier, monospace;
                font-size: 26px;
                color: var(--color-marron);
                margin: 3% 2%;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .photoblock-container {
        h2 {
            font-size: 36px;
        }
        .photoblock-imgtext-container {
            .photoblock-img {
                img {
                    width: 200px;
                }
            }
            .photoblock-text {
                p {
                    font-size: 18px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .photoblock-container {
        h2 {
            font-size: 32px;
        }
        .photoblock-imgtext-container {
            flex-direction: column;
            max-height: none;
            justify-content: flex-start;
            margin-top: 3%;
            .photoblock-img {
                img {
                    width: 200px;
                }
            }
            .photoblock-text {
                width: 80%;
                p {
                    font-size: 18px;
                }
            }
        }
    }
}
