.blocinsta-container{
    height: 450px;
    background-color: var(--color-marron);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2% 0;

    h3 {
        margin-bottom: 2%;
    }
    .instagram-posts{
        display: flex;
        justify-content: center;
        gap: 2%;

        .instagram-post{
            img{
                width: 200px;
                height: 250px;
                object-fit: cover;
                border: 1px solid #3b1f0b;
                box-shadow: 0 4px 4px 0 rgba(59, 31, 11 , 0.5);
            }
        }
    }



}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .blocinsta-container{ 
        height: 350px;
        .instagram-posts{ 
            gap: 1%;
            .instagram-post{ 
                img{
                    width: 140px;
                    height: 190px;
                }}}}
}

@media screen and (max-width: 767px){
    .blocinsta-container{
        align-items: center;
        height: 450px;
        h3{
            font-size: 26px;
            margin: 3% 2%;
            br.hide-on-small-screen{
                display: none;
            }
        }
        .instagram-posts{
            gap: 0.5%;
            width: 85%;
            flex-wrap: wrap;
            .instagram-post{
                
                img{
                width: 100px;
                height: 150px;
            }
            }
        }
    }
}